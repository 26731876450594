import React, {useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';

import "react-image-gallery/styles/css/image-gallery.css"; // Import the default styles


import "./home.css"
import "./home5.css"

export default function Home5(){
    //i18n
    const { t, i18n } = useTranslation('home');

    const [isSizeLG, setIsSizeLG] = useState((window.innerWidth >= 992? true: false)); 

    const items = [
        {
            thumbnail: '/images/home/gal_tb_01.jpg',
            original: '/images/home/gal_01.jpg',
        },
        {
            thumbnail: '/images/home/gal_tb_01.jpg',
            original: '/images/home/gal_01.jpg',
        },
        {
            thumbnail: '/images/home/gal_tb_01.jpg',
            original: '/images/home/gal_01.jpg',
        },
        {
            thumbnail: '/images/home/gal_tb_01.jpg',
            original: '/images/home/gal_01.jpg',
        },
        {
            thumbnail: '/images/home/gal_tb_01.jpg',
            original: '/images/home/gal_01.jpg',
        },
    ]

    const thumbnailInner = (item) => {
        return(
            <div className='thumbnail d-flex flex-column'>
                <img className='img-fluid' src={item.thumbnail} />
            </div>
        )
    }

    const myRenderItem = (item) => {
        return (
            <div className='item'>
                <img className='img-fluid' src={item.original} />
            </div>
        )
    }

    const myRenderRightNav = (onClick, disabled) => {
        return (
            <img src= {'./images/home/arrow_right.png'}
                className= { 'image-gallery-right-nav'}
                disabled={disabled}
                onClick={onClick}
                aria-label='Next Slide'
            >

            </img>
        );
    };

    const myRenderLeftNav = (onClick, disabled) => {
        return (
            <img src= {'./images/home/arrow_left.png'}
                className= { 'image-gallery-left-nav'}
                disabled={disabled}
                onClick={onClick}
                aria-label='Next Slide'
            >

            </img>
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        if (window.innerWidth >= 992) {
            setIsSizeLG(true);
        }else{
            setIsSizeLG(false);
        }
    }


    return(
        <div id='home5' className='home5 mt-2'>
            <div className='mlogo mx-auto mx-1 my-4'>
                <img className='img-fluid' src='images/home/mgallery.png' />
            </div>
                <div className='gal-container maxw-1920 mt-2'>
                    <ImageGallery
                        items={items}
                        showIndex={false}
                        showThumbnails={true}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        showNav={true}
                        infinite={true}
                        thumbnailPosition={(isSizeLG? 'right': 'bottom')}
                        renderRightNav = { myRenderRightNav }
                        renderLeftNav = { myRenderLeftNav }
                        renderThumbInner = {thumbnailInner}
                        renderItem = {myRenderItem}
                        // onSlide={handleOnSlide}
                    />
                </div>
        </div>
    )
}