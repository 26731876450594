import React, {useRef, useState, useEffect, useImperativeHandle, forwardRef  } from 'react';
import { useLocation, Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material'; 
import Modal from '@mui/material/Modal';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import axios from "axios";

import "./right-panel.scss"
import { Button } from 'bootstrap/dist/js/bootstrap.bundle.min';
import QuestProgress from './quest_progress';
import SliderBillboard from './slider-billboard';

function RightPanel(props, ref){

    const bt_start = 0;
    const bt_shoot = 1;
    const bt_wait = 2
    const bt_continue = 3;

    const [btStage, setBtStage] = useState(bt_start);
    const [freeBulletCount, setFreeBulletCount] = useState(10);

    const onStartClick = () => {
        setBtStage(bt_shoot);
        props.onStart();
    }

    const onShootClick = () => {
        props.onShoot();
        onShootProcess();
        
    }

    useImperativeHandle(ref, ()=> ({
        onShootProcess() {
            onShootProcess();
        }
    }))

    const onShootProcess = () => {
        setBtStage(bt_wait);
        setTimeout(() => {
            setBtStage(bt_continue);
        }, 300);
    }

    const onContinueClick = () => {
        props.onContinue();
        setBtStage(bt_start);
    }

    
    return(
        <div className='right-panel p-0 m-0 d-flex flex-column justify-content-between'>
            <div className='panel-top me-2 d-flex flex-row justify-content-end'>
                <img className='bt-quest img-fluid my-auto mx-2' src='/images/events/chapter1/bt-quest.png' onClick={props.onQuest} />
                <img className='bt-sound img-fluid my-auto me-2' src='/images/events/chapter1/bt-sound.png' onClick={props.onSound} />
            </div>
            <div className='panel-bullet p-0 pt-1 me-0 ms-auto'>
                <div className='bullet-area free p-0 m-0'>
                    <div className='reset-label free d-flex justify-content-end pe-4'>RESET AT 23:59 DAILY</div>
                    <img className='bullet-bg free' src='/images/events/chapter1/bg-free-bullet.png' />
                    <div className={`bullet-count free ${btStage == bt_wait? 'bullet-deduct':''}`}>{props.freeBullet}</div>
                </div>
                <div className='bullet-label free p-0'>FREE BULLET</div>
                <div className='bullet-area cash p-0 m-0'>
                    <img className='bullet-bg cash' src='/images/events/chapter1/bg-cash-bullet.png' />
                    <div className='bullet-count cash'>5</div>
                </div>
                <div className='bullet-label cash p-0'>CASH BULLET</div>
            </div>
            <div className='panel-bt mb-0 mx-auto d-flex flex-column justify-content-end'>
                    {
                        (btStage == bt_start) &&
                        <img className='bt-start img-fluid p-2 m-auto' 
                            onClick={() => onStartClick()}
                            src='/images/events/chapter1/bt-start.png' />
                    }
                    {
                        (btStage == bt_shoot) && 
                        <img className={`bt-shoot ${props.targeted == null? '': 'showed'} img-fluid p-2 m-auto`}
                            onClick={() => onShootClick()}
                            src='/images/events/chapter1/bt-shoot.png' />
                    }
                    {
                        (btStage == bt_continue || btStage == bt_wait) &&
                        <img className={`bt-continue ${(btStage == bt_wait? 'waiting':'')} img-fluid p-2 m-auto`} 
                            onClick={() => onContinueClick()}
                            
                            src='/images/events/chapter1/bt-continue.png' />
                    }
            </div>
        </div>
    )
}

export default forwardRef(RightPanel);