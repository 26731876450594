import React, {useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';

import "react-image-gallery/styles/css/image-gallery.css"; // Import the default styles


import "./home.css"
import "./home3.css"

export default function Home3(){
    //i18n
    const { t, i18n } = useTranslation('home');
    
    const maps = [
        {
            key: 'time_square',
            thumbnail: '/images/home/thumb_map01.jpg',
            image: '/images/home/map01.jpg',
            enemies: [
                'images/home/SK_Zombie_Businessman_Male_01.png',
                'images/home/SK_Zombie_Business_Female_01.png',
                'images/home/SK_Zombie_BusinessShirt_Male_01.png',
                'images/home/SK_Zombie_Coat_Female_01.png',
            ],
            enemies_size: [],
            
        },
        {
            key: 'desert_mine',
            thumbnail: '/images/home/thumb_map02.jpg',
            image: '/images/home/map02.jpg',
            enemies: [
                {path: '/images/home/SK_Zombie_Mega_Male_01.png', size: 5},
                '/images/home/SK_Zombie_HotDog_01.png',
                '/images/home/SK_Zombie_BigJane_01.png',
                
            ],
        },
        {
            key: 'mysterious_island',
            thumbnail: '/images/home/thumb_map03.jpg',
            image: '/images/home/map03.jpg',
            enemies: [
                {path: '/images/home/SK_Zombie_Mega_Female_01.png', size: 5},
                {path: '/images/home/SK_Zombie_Pierrot_01.png', size: 3},
                {path: '/images/home/SK_Zombie_BigJohn_01.png', size: 3},
                
            ],
            enemies_size: [1],
        },
        {
            key: 'terrorist_road',
            thumbnail: '/images/home/thumb_map04.jpg',
            image: '/images/home/map04.jpg',
            enemies: [
                '/images/home/SK_Zombie_Jacket_Male_01.png',
                '/images/home/SK_Zombie_BigJohn_01.png',
                '/images/home/SK_Zombie_Military_Male_01.png',
                '/images/home/SK_Zombie_BigJane_01.png',
            ],
        },
        {
            key: 'airforce_base',
            thumbnail: '/images/home/thumb_map05.jpg',
            image: '/images/home/map05.jpg',
            enemies: [
                '/images/home/SK_Zombie_HotDog_01.png',
                '/images/home/SK_Zombie_Pierrot_01.png',
                '/images/home/SK_Zombie_Military_Male_01.png',
                '/images/home/SK_Zombie_BigJohn_01.png',
            ],
        },
        {
            key: 'bank_town',
            thumbnail: '/images/home/thumb_map06.jpg',
            image: '/images/home/map06.jpg',
            enemies: [
                '/images/home/SK_Zombie_Son_Male_01.png',
                '/images/home/SK_Zombie_Mother_Female_01.png',
                '/images/home/SK_Zombie_RiotCop_Male_01.png',
                '/images/home/SK_Zombie_Police_Female_01.png',

            ],
        },
        {
            key: 'safecity',
            thumbnail: '/images/home/thumb_map07.jpg',
            image: '/images/home/map07.jpg',
            enemies: [
                '/images/home/SK_Zombie_Grandma_Female_01.png',
                '/images/home/SK_Zombie_SchoolBoy_Male_01.png',
                '/images/home/SK_Zombie_SchoolGirl_Female_01.png',
                '/images/home/SK_Zombie_Grandpa_Male_01.png',

            ],
        },
        {
            key: 'desert_storm',
            thumbnail: '/images/home/thumb_map08.jpg',
            image: '/images/home/map08.jpg',
            enemies: [
                '/images/home/SK_Zombie_Tourist_Male_01.png',
                '/images/home/SK_Zombie_Police_Male_01.png',
                '/images/home/SK_Zombie_Jacket_Female_01.png',
                '/images/home/SK_Zombie_SummerGirl_Female_01.png',
            ],
        },
        {
            key: 'swamp_mz',
            thumbnail: '/images/home/thumb_map09.jpg',
            image: '/images/home/map09.jpg',
            enemies: [
                '/images/home/SK_Zombie_Tourist_Male_01.png',
                '/images/home/SK_Zombie_Police_Male_01.png',
                '/images/home/SK_Zombie_Jacket_Female_01.png',
                '/images/home/SK_Zombie_SummerGirl_Female_01.png',
            ],
        },
        {
            key: 'swamp',
            thumbnail: '/images/home/thumb_map10.jpg',
            image: '/images/home/map10.jpg',
            enemies: [
                {path: '/images/home/SK_Zombie_Mega_Male_01.png', size:5},
                '/images/home/SK_Zombie_Hobo_Male_01.png',
                '/images/home/SK_Zombie_Diver_Male_01.png',

            ],
        },
        {
            key: 'rooftop',
            thumbnail: '/images/home/thumb_map11.jpg',
            image: '/images/home/map11.jpg',
            enemies: [
                '/images/home/SK_Zombie_Prisoner_Male_01.png',
                '/images/home/SK_Zombie_Punk_Male_01.png',
                '/images/home/SK_Zombie_Punk_Female_01.png',
                '/images/home/SK_Zombie_Cheerleader_01.png',
            ],
        },
        
    ]

    const myRenderRightNav = (onClick, disabled) => {
        return (
            <img src= {'./images/home/arrow_right.png'}
                className= { 'image-gallery-right-nav'}
                disabled={disabled}
                onClick={onClick}
                aria-label='Next Slide'
            >

            </img>
        );
    };

    const myRenderLeftNav = (onClick, disabled) => {
        return (
            <img src= {'./images/home/arrow_left.png'}
                className= { 'image-gallery-left-nav'}
                disabled={disabled}
                onClick={onClick}
                aria-label='Next Slide'
            >

            </img>
        );
    };

    const thumbnailInner = (item) => {
        return(
            <div className='inner'>
                <img className='img img-fluid' src={item.thumbnail} alt={item.name} />
            </div>
        )
    }
    
    const myRenderItem = (item) => {
        const enemies = item.enemies.map((zombie) => (
            <Col xs={zombie?.size || 3}>
                <img className='enemy' src={zombie?.path || zombie} />
            </Col>
        ));

        return(
            //window.innerWidth
            <div className='item d-flex flex-column justify-content-start p-0'>
                <div className='map d-flex ms-0 p-0'>
                    <img className='img-fluid' src={item.image} />
                </div>
                <div className='content d-flex'>
                    <div className={'content-inner m-1 d-flex flex-column tcontext ' + (window.innerWidth >= 992? 'my-auto':'m-auto')}>
                        <div className='name'><h1 className='t-border-black'>{t(`maps.${item.key}.name`)}</h1></div>
                        <div className='content-context text-wrap'>
                            {t(`maps.${item.key}.story`)}
                        </div>
                        <p></p>
                        <div><h1 className='t-border-black'><span style={{color: '#AE3C33'}}>BOSS</span> & ENERMY</h1></div>
                        <Row className='enemies d-flex justify-content-center mx-auto'>
                            {enemies}
                        </Row>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className='home3 maxw-1920 mx-auto'>
            <ImageGallery
                items={maps}
                showIndex={false}
                showThumbnails={true}
                showPlayButton={false}
                showFullscreenButton={false}
                showNav={true}
                infinite={true}
                thumbnailPosition={'top'}
                renderRightNav = { myRenderRightNav }
                renderLeftNav = { myRenderLeftNav }
                renderThumbInner = {thumbnailInner}
                renderItem = {myRenderItem}
            />
        </div>
    )
}