import React, { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Col, Row, Container } from 'react-bootstrap';

import {Hearts} from 'react-loader-spinner';

// import { useTranslation } from 'react-i18next';
import * as zendesk from './zendesk'

import "./ticket.css"


export default function Ticket(){
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        subject: '',
        name: '',
        email: '',
        uuid: '',
        message: '',
        upload1: null,
        upload2: null,
        upload3: null,
        err: null,
        ticketid: null,
    });

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        const newValue = type === 'file' ? files[0] : value;

        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: newValue,
        }));
    };

    const handleNumberChange = (e) => {
        const inputNumber = e.target.value;

        console.log("number input: " + inputNumber);
        if ((/^\d+$/.test(inputNumber) || (inputNumber === '')) && inputNumber.length <= 17) {
            console.log("setFormData: " + inputNumber);
            setFormData((prevFormData) => ({
                ...prevFormData,
                uuid: inputNumber,
            }));
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault();

        formData.err = '';
        setLoading(true);
        // setError(false);
        
        // Handle form submission, e.g., send data to the server
        

        // console.log('Ticket data:', ticketData);
        let tokens = [];
        try{
            // console.log("tokens....")
            // console.log(tokens);
            // console.log("formdata...");
            // console.log(formData);

            
            //upload zendesk file
            if(formData.upload1 != null){
                const upres1 = await zendesk.uploadFile(formData.upload1, 'file1');
                tokens = [...tokens, upres1.data.upload.token];
            }

            //upload zendesk file
            if(formData.upload2 != null){
                const upres2 = await zendesk.uploadFile(formData.upload2, 'file2');
                tokens = [...tokens, upres2.data.upload.token]
            }
            
            //upload zendesk file
            if(formData.upload3 != null){
                const upres3 = await zendesk.uploadFile(formData.upload3, 'file3');
                tokens = [...tokens, upres3.data.upload.token]
            }

            // console.log("tokens...");
            // console.log(tokens);
            const ticketData = zendesk.createTicketData(formData.subject, formData.name, formData.email, "support@gmillion.io"
                ,`uuid: ${formData.uuid} \n ${formData.message}`
                , tokens);

            //create zendeks ticket
            // console.log("ticketData...")
            // console.log(ticketData);
            const res = await zendesk.createTicket(ticketData);
            
            // console.log(res);
            formData.ticketid = res.data.request.id;


        }catch(err){
            console.log(err); 
        }
        setLoading(false);

    };

    return(
        <div className='ticket'>
            {/* <div fluid class='mx-auto img-fixedbg img-fluid' style={{backgroundImage: 'url(' + require('./bg.jpg') + ')'}} /> */}
            <div className='bg'>
                <img className='' src='/images/home/home1_bg.jpg' />
            </div>
            
            {(loading && <div className='loader'>
                <Hearts visible={true} ariaLabel="loading" color="#ff8e0f"
                                wrapperStyle={{}} wrapperClass="dna-wrapper hearts" />
            </div>)}
            
            <Container fluid className='form-container mx-auto maxw-768'>
                {(!formData.ticketid && <form onSubmit={handleSubmit} className='form mx-auto d-flex flex-column p-4'>
                    <div id='header' className='form-logo d-flex flex-row mx-auto mb-4'>
                        <a className='mx-auto' href='/home'><img className='img-fluid mx-auto' src='images/logo_500.png' /></a>
                    </div>
                    <p><h4>ข้อเสนอแนะและการแจ้งปัญหาของเกม</h4></p>
                    <br/>
                    <p><label className='required'>1. หัวข้อ</label></p>
                    <input type="text" name="subject" maxLength="254" required placeholder='เรื่องมีอยู่ว่า...'
                        value={formData.subject} onChange={handleChange} />
                    <br/>
                    <p><label className='required'>2. ชื่อนามสกุลของผู้ติดต่อ</label></p>
                    <input type="text" name="name" maxLength="254" required placeholder='ชื่อ นามสกุล'
                        value={formData.name} onChange={handleChange} />
                    <br/>
                    <p><label className='required'>3. อีเมล</label></p>
                    <input type="text" name="email" maxLength="254" required placeholder='your_email@email.com'
                        value={formData.email} onChange={handleChange} />
                    <br/>
                    <p><label className='required'>3. หมายเลขผู้เล่น</label>
                    <br/><l className='desc'>คำอธิบาย: คุณสามารถคลิก เมนู > ตั้งค่า > การเชื่อมต่อ หมายเลขผู้เล่นจะแสดงทั้งหมด 17 หลัก</l>
                    </p>
                    <input type="text" name="uuid" maxLength="17" required placeholder='12345678901234567'
                        value={formData.uuid} onChange={handleNumberChange} />
                    <br/>

                    <p><label className='required'>4. ข้อความที่ต้องการติดต่อสอบถาม</label> 
                    </p>
                    <textarea name="message" maxLength="5120" required placeholder='กาลครั้งหนึ่ง เมื่อไม่นานมานี้...'
                        value={formData.message} onChange={handleChange} />
                    <br/>

                    <p><label>แนบ file 1</label><br/>
                    <input type="file" name="upload1" onChange={handleChange} accept="*.jpg"/></p>
                    <p><label>แนบ file 2</label><br/>
                    <input type="file" name="upload2" onChange={handleChange} accept="*.jpg"/></p>
                    <p><label>แนบ file 3</label><br/>
                    <input type="file" name="upload3" onChange={handleChange} accept="*.jpg"/></p>
                    <br/>
                    
                    <br/>
                    <div className='d-flex'>
                        <button type="submit">ตกลง</button>
                        <div className='error mx-3'>
                            <p>{(formData.err && formData.err.message)}</p>
                        </div>
                        {/* <div className='error mx-3'>testttt</div> */}
                    </div>
                </form>)}

                {(formData.ticketid && <div className='response d-flex flex-column p-4'>
                    <div className='header mx-auto'>
                        <a className='' href='/home'><img className='img-fluid mx-auto' src='images/logo_500.png' /></a>
                    </div>
                    <div className='context mt-5 mx-5'>
                        <p><h4>ข้อเสนอแนะและการแจ้งปัญหาของเกม</h4></p>
                        <br/>
                        {`คำร้องขอถูกสร้างสำเร็จ หมายเลขคำร้องขอของท่านคือ ${formData.ticketid} \n
                        หากต้องการสอบถามข้อมูลอื่นๆที่เกี่ยวข้อง รบกวนแจ้งหมายเลขคำร้องขอ ${formData.ticketid} ของท่าน`}
                    </div>
                    <img src='/images/home/zombies_layer2_light.png' className='img-bg transi-ease-int-out' />
                </div>)}
            </Container> 
            
            <br/>
        </div>
    )
}
