import React, {useRef, useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material'; 
import Modal from '@mui/material/Modal';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import axios from "axios";


import "./modal1.scss"
import { Button } from 'bootstrap/dist/js/bootstrap.bundle.min';

export default function Modal1(props){
    return(
        <Modal keepMounted open={props.open} onClose={props.onClose} 
            className={'d-flex ' + props.className }
        >
            <div className='modal1 d-flex rounded m-auto p-3 d-flex flex-column'>
                {props.header && <props.header />}
                {props.context && <props.context />}
                <div className='bt-area d-flex'>
                    <button className='bt-close mx-auto m-1 py-2 px-4' onClick={() => props.onClose()}>CLOSE</button>
                </div>
                <img className='bt-exit mt-auto mb-2' onClick={() => props.onClose()} src="/images/events/chapter1/bt-close.png"/>
            </div>
        </Modal>
    )
}