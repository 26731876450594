import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Helper for populating a JSON with all the translation strings.
// With this, each locale will be populated with namespaces based on the filename
const compileStrings = ctx => {
  const compiled = { resources: {}, ns: [] };
  // This regex does not work for en-US, en-UK, etc.
  const fileRegex = /(?<context>\w*)\/(?<namespace>\w+)\/(?<fileName>\w+)\.(?<locale>\w+)\.json$/;

  ctx.keys().forEach(file => {
    const { fileName, namespace, locale } = file.match(fileRegex).groups;

    compiled.ns.push(namespace);
    compiled.resources[locale] = !!compiled.resources[locale] ? compiled.resources[locale] : {};
    compiled.resources[locale][fileName] = ctx(file);
  });

  // Filter unique namespaces
  compiled.ns = compiled.ns.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  return compiled;
};

// const strings = compileStrings(require.context('../locales', true, /\.\w+\.json$/));

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // resources: strings.resources,
    // ns: strings.ns,
    defaultNS: 'common',
    // lng: localStorage.getItem(I18N_LANGUAGE) || "th",
    fallbackLng: "th",
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false
    },
    returnObjects: true,

    // detection: {
    //   order: ["localStorage", "navigator"],
    //   lookupQuerystring: "lng",
    //   lookupLocalStorage: I18N_LANGUAGE,
    //   caches: ["localStorage"]
    // }

    // // keys or params to lookup language from
    // lookupQuerystring: 'lng',
    // lookupCookie: 'i18next',
    // lookupLocalStorage: 'i18nextLng',
    // lookupSessionStorage: 'i18nextLng',
    // lookupFromPathIndex: 0,
    // lookupFromSubdomainIndex: 0,

    // // cache user language on
    // caches: ['localStorage', 'cookie'],
    // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // // optional expire and domain for set cookie
    // cookieMinutes: 10,
    // cookieDomain: 'gmillion.io',

    // // optional htmlTag with lang attribute, the default is:
    // htmlTag: document.documentElement,

    // // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    // cookieOptions: { path: '/', sameSite: 'strict' }
  });

 export default i18n;