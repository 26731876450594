import React, {useRef, useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material'; 
import Modal from '@mui/material/Modal';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import axios from "axios";

import "./quest_progress.scss"
import { Button } from 'bootstrap/dist/js/bootstrap.bundle.min';

export default function SliderBillboard(){
    return(
        <div className='sliderbillboard d-flex'>
            <div className='billboard-bg d-flex mt-3 mb-5 pb-2'>
                <img className='img-fluid' src='/images/events/chapter1/billboard.png'></img>
            </div>
            {/* <div className='billboard-slider'>

            </div> */}
        </div>
    )
}