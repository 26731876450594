import axios from 'axios';

const apiUrl = 'https://gmillionsupport.zendesk.com/api/v2';
//todo change thise token to megazombie (current: iloveburger)
const apiToken = 'Basic ZGV2QGdtaWxsaW9uLmlvL3Rva2VuOjd2dUxMbm8yTVBId3Y3cDB6SmN0SU9tcW1YY0Vla3dZYWFVT3NoMTk=';
// const groupid= '6900394923023'  //ilove burger group
const ticketgroup = 'megazombiem';

export function createTicketData(subject, name , email, assigness_email, message, upload){
    const data = {
            request: {
            subject: subject,
            allow_attacments: true,
            public: "true",
            status: "open",
            "custom_fields": [{"id": 8153488208911, "value": ticketgroup}],
            requester: {name: name, email: email},
            assigness_email: assigness_email,
            type: "question",
            via: {channel: "api"},
            comment: {
                body: message,
                uploads: upload
            }
        }
    }
    return data;
}

export async function uploadFile(filedata, filename){ 
    try{
        const response = await axios.post(`${apiUrl}/uploads?filename=${filename}`, filedata, {
            headers: {
              'Content-Type': 'application/binary',
              'Authorization': apiToken,
              'Accept': '*/*',
            },
        });
        return response;
    }catch(error){
        throw error;
    }
}

export async function createTicket(formData){
    try {
        // console.log(JSON.stringify(formData));
        const response = await axios.post(`${apiUrl}/requests`, JSON.stringify(formData), {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': apiToken,
              'Accept': '*/*',
            },
        });
  
        return response;
    } catch (error) {
        // console.error('Error:', error);
        throw error;
    }
}