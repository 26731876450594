import React, {useRef, useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material'; 
import Modal from '@mui/material/Modal';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import axios from "axios";
 

import "./sample.css"

const path = 'https://qapi-mzm.gmillion.io';
// const path = 'http://localhost:3000/dev';



export default function EVSample(){
    //i18n
    const { t, i18n } = useTranslation('events/sample');

    const location = useLocation();
    const uuid = new URLSearchParams(location.search).get("uuid")
    const token = new URLSearchParams(location.search).get("token")


    const [eventStage, setEventStage] = useState({
        event1: 0,
        event2: 0,
        event3: 0,
        event4: 0,
        event5: 0,
        event6: 0,
        event7: 0,
        event8: 0,
        event9: 0,
        event10: 0,
        event11: 0,
        event12: 0,

    })

    const handleClick = async(e) => {
        e.preventDefault();

        //example that event_id: 1 was click
        const jdata = {
            uuid: uuid,
            token: token,
            event: 1,
        }

        try{
            const res =  await axios.put(
                `${path}/events`,
                JSON.stringify(jdata),
                {
                headers: { 
                    "Content-Type": "application/json",
                    // "Authorization": withAccessToken ? `Bearer ${cooker.accessToken()}` : '',
                    // ...headers,
                },
                withCredentials: false,
                }
            );

            
        }catch(ex){

        }

        //example that the reward of event 1 was genereated
        setEventStage((prev) => ({
            ...prev,
            event1: 2,
        }));
    }

    const getQuest = async(e) => {
        const jdata = {
            uuid: uuid,
            token: token,
            event: [
               1,2,3,4,5,6,7,8,9,10,11,12     
            ]
        }
    
        try{
            const res =  await axios.post(
                `${path}/events`,
                JSON.stringify(jdata),
                {
                headers: { 
                    "Content-Type": "application/json",
                    // "Authorization": withAccessToken ? `Bearer ${cooker.accessToken()}` : '',
                    // ...headers,
                },
                withCredentials: false,
                }
            );

            //example assume that response return will only event_id 1, stage = 1, the less stage =0
            // setEventStage()
            setEventStage((prev) => ({
                ...prev,
                event1: 1,
            }));
        }catch(ex){

        }
    }

    //Run once on page load
    const initialized = useRef(false);
    useEffect(() => {
        if (initialized.current) {
            return;
        }

        initialized.current = true

        getQuest();
    }, []);

    return(
        <div className='evsample'>
            <img className='bg' src='/images/events/sample/BG_MZM.jpg' />
            <div className='context'>
                <div className='hdtext1 mt-5 pt-5'>
                    General and Daily Quest
                </div>
                <div className='hdtext2'>
                    login and play daily quest for special gift
                </div>

                <div className='tb1 mx-5 pt-1'>
                    <div className='tb-text'>Daily Quest</div>
                    <div className='w-100 p-1 d-flex'>
                        <div className='item mx-1'>
                            <div className='text1 mx-auto'>Login 1 time</div>
                            <img className='' src="/images/events/sample/SM_Chr_Attach_Back_12.png" />
                            <div className='text2 mx-auto'>Skull Bag 7 days</div>
                            {/* {(eventStage.event1==0) && <button className='sg0 py-0 my-2' disabled={true}>In progress</button>} */}
                            {(eventStage.event1==1) && <button className='sg1 py-0 my-2' disabled={false} 
                                onClick={handleClick}>Accept</button>}
                            {(eventStage.event1==2) && <button className='sg2 py-0 my-2' disabled={true}>Done</button>}
                        </div>

                        <div className='item mx-1'>
                            <div className='text1 mx-auto'>Play TDM Mode 1 time</div>
                            <img className='' src="/images/events/sample/P90+S.png" />
                            <div className='text2 mx-auto'>P90 30 days</div>
                            {/* <button className='sg0 py-0 my-2' disabled>In progress</button> */}
                        </div>

                        <div className='item mx-1'>
                            <div className='text1 mx-auto'>Online 30 mins</div>
                            <img className='' src="/images/events/sample/Crutch.png" />
                            <div className='text2 mx-auto'>Crutch 3 days</div>
                            {/* <button className='sg0 py-0 my-2' disabled>In progress</button> */}
                        </div>

                        <div className='item mx-1'>
                            <div className='text1 mx-auto'>Online 1 hours</div>
                            <img className='' src="/images/events/sample/SM_Drone_Attacker.png" />
                            <div className='text2 mx-auto'>ATK Drone 3 days</div>
                            {/* <button className='sg0 py-0 my-2' disabled>In progress</button> */}
                        </div>

                    </div>
                </div>

                <br/>
                <div className='tb1 mx-5 pt-1'>
                    <div className='tb-text'>Daily Social Media</div>
                    <div className='w-100 p-1 d-flex'>
                        <div className='item mx-1'>
                                <div className='text1 mx-auto'>Online 1 hours</div>
                                <img className='' src="/images/events/sample/SM_Chr_Attach_Mask_Hockey_01.png" />
                                <div className='text2 mx-auto'>ATK Drone 3 days</div>
                                {/* <button className='sg0 py-0 my-2' disabled>In progress</button> */}
                        </div>

                        <div className='item mx-1'>
                                <div className='text1 mx-auto'>Online 1 hours</div>
                                <img className='' src="/images/events/sample/MK3A2.png" />
                                <div className='text2 mx-auto'>ATK Drone 3 days</div>
                                {/* <button className='sg0 py-0 my-2' disabled>In progress</button> */}
                        </div>

                        <div className='item mx-1'>
                                <div className='text1 mx-auto'>Online 1 hours</div>
                                <img className='' src="/images/events/sample/SM_Chr_Attach_Hair_Male_02.png" />
                                <div className='text2 mx-auto'>ATK Drone 3 days</div>
                                {/* <button className='sg0 py-0 my-2' disabled>In progress</button> */}
                        </div>

                        <div className='item mx-1'>
                                <div className='text1 mx-auto'>Online 1 hours</div>
                                <img className='' src="/images/events/sample/SM_Prop_Pool_Float_Ring_03.png" />
                                <div className='text2 mx-auto'>ATK Drone 3 days</div>
                                {/* <button className='sg0 py-0 my-2' disabled>In progress</button> */}
                        </div>
                    </div>
                </div>

                <br />
                <div className='tb1 mx-5 pt-1'>
                    <div className='tb-text'>Daily Social Media</div>
                    <div className='w-100 p-1 d-flex'>
                        <div className='item mx-1'>
                                <div className='text1 mx-auto'>Online 1 hours</div>
                                <img className='' src="/images/events/sample/AK12.png" />
                                <div className='text2 mx-auto'>ATK Drone 3 days</div>
                                {/* <button className='sg0 py-0 my-2' disabled>In progress</button> */}
                        </div>

                        <div className='item mx-1'>
                                <div className='text1 mx-auto'>Online 1 hours</div>
                                <img className='' src="/images/events/sample/M4+E.png" />
                                <div className='text2 mx-auto'>ATK Drone 3 days</div>
                                {/* <button className='sg0 py-0 my-2' disabled>In progress</button> */}
                        </div>

                        <div className='item mx-1'>
                                <div className='text1 mx-auto'>Online 1 hours</div>
                                <img className='' src="/images/events/sample/ITEM.png" />
                                <div className='text2 mx-auto'>ATK Drone 3 days</div>
                                {/* <button className='sg0 py-0 my-2' disabled>In progress</button> */}
                        </div>

                        <div className='item mx-1'>
                                <div className='text1 mx-auto'>Online 1 hours</div>
                                <img className='' src="/images/events/sample/ITEM.png" />
                                <div className='text2 mx-auto'>ATK Drone 3 days</div>
                                {/* <button className='sg0 py-0 my-2' disabled>In progress</button> */}
                        </div>
                    </div>
                </div>

                < br/>
                <div className='footer ms-0 w-100 ps-5 mb-0 py-2'>
                    <div>Notice</div>
                    <div>
                        1. xxxxxxxxxxx
                    </div>
                    <div>
                        2. xxxxxxxxxxx
                    </div>
                    <div>
                        3. xxxxxxxxxxx
                    </div>
                </div>
            </div>
        </div>
    )
}