import React, {useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';

import "react-image-gallery/styles/css/image-gallery.css"; // Import the default styles


import "./header.css"
export default function Header(){
    //i18n
    const { t, i18n } = useTranslation('home');

    return(
        <div className='header pt-1'>
            <div className='logo mx-auto'>
                <a href='/home'><img className='img-fluid' src='images/logo_500.png' /></a>
            </div>
            
        </div>
    )
}