import React, {useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import axios from 'axios';


import "./policies.css"

import Header from './header';

export default function Policies(){
    const location = useLocation();

    //i18n
    const { t, i18n } = useTranslation('policies');

    const [htmlContent, setHtmlContent] = useState('');
    const [isToggle, setIsToggle] = useState(false);
    const [isattop, setIsAtTop] = useState(true)

    
    const doc = (new URLSearchParams(location.search).get("doc")) || 'privacy';
    const docPath = `/html/${doc}.html`;

    useEffect(() => {
        const fetchHtmlContent = async () => {
            try {
            const response = await axios.get(docPath);
            setHtmlContent(response.data);
            } catch (error) {
            console.error('Error fetching HTML content:', error);
            }
        };
    
        fetchHtmlContent();
    });

    const doToggle = () => {
        setIsToggle(!isToggle);
    }

    //navbar scroll changing function
    const checkIsAtTop = () => {
        if (window.scrollY >= 32) {
            setIsAtTop(false);
        }else{
            setIsAtTop(true);
        }
    }



    useEffect(() => {
        checkIsAtTop()
        // adding the event when scroll change background
        window.addEventListener("scroll", checkIsAtTop)
    })

    return(
        <div className='policies'>
            <div className={'layer11 p-0 m-0 d-block d-lg-none' + (isToggle? ' toggled': '')} >
                {isToggle && <div className='props'>
                    <img className='prop01' src='./images/home/mmenu_prop01.png'/>
                    <img className='prop02' src='./images/home/mmenu_prop02.png'/>
                    <img className='prop03' src='./images/home/mmenu_prop03.png'/>
                    <img className='prop04' src='./images/home/mmenu_prop04.png'/>
                </div>}
                <Navbar collapseOnSelect expand="lg" bg="transparent" variant="none" sticky="none"
                    className={'mt-5'} >
                    <Navbar.Toggle aria-controls="basic-navbar-nav" 
                        className='navtoggle px-2 py-0 me-1' onClick={()=> doToggle()} >
                            <img className='hamburger img-fluid m-auto' 
                                src={isToggle? './images/home/burger_toggled.png' : './images/home/burger.png'}></img>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" className='w-100 mt-0 '>
                        <div className='d-flex flex-column mx-auto justify-content-center'>
                            
                            {/* <div className='full-screen-menu w-100 d-block d-xl-none'> */}
                                {/* logo and center objects */}
                                {/* <div className='d-flex'>    */}
                                    <div className='logo mx-auto'>
                                        <img className='img-fluid mx-auto' src='images/logo_500.png' />
                                    </div>
                                {/* </div> */}
                                {/* <div className='layer13 d-flex justify-content-end'>
                                    <div className='navtoggle p-0 mt-2 me-1' onClick={()=> doToggle()}>
                                        <img className='hamburger img-fluid m-auto' 
                                            src='./images/home/burger_toggle.png'></img>
                                    </div>
                                </div>  */}
                            {/* </div> */}
                            

                            <a href='/home'><h1 className='header-item t-border-red t-shadow25 my-4'>OVERVIEW</h1></a>
                            <a href='/home#home4'><h1 className='header-item t-border-red t-shadow25 my-4'>CHARACTER</h1></a>
                            <a href='/ticket'><h1 className='header-item t-border-red t-shadow25 my-4'>SUPPORT</h1></a>
                            <a href='/policies?doc=privacy'><h1 className='header-item t-border-red t-shadow25 my-4'>POLICY</h1></a>
                        
                        </div>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            <Header/>
            <div className='subheaders d-flex flex-row justify-content-start'>
                <div className='subheader mx-2 t-out-shadow '>
                    <Link to="/policies?doc=privacy"><h1 className={(doc==='privacy'?' header-selected':'')}>PRIVACY POLICY</h1></Link>
                </div>
                <div className='subheader mx-2 t-out-shadow '>
                    <Link to="/policies?doc=tos"><h1 className={(doc==='tos'?'header-selected':'')}>TERM OF SERVICE</h1></Link>
                </div>
                {/* <div className='subheader mx-2 t-out-shadow '>
                    <Link to="/policies?doc=cookies"><h1 className={(doc==='cookies'?'header-selected':'')}>COOKIES POLICY</h1></Link>
                </div> */}
            </div>
            <div className='contexts'>
                <div className='htmlcontext mx-auto' dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </div>
        </div>
    )
}