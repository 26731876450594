import React, {useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';

import "react-image-gallery/styles/css/image-gallery.css"; // Import the default styles


import "./home.css"
import "./footer.css"

export default function Footer(){
    //i18n
    const { t, i18n } = useTranslation('home');

    return(
        <div className='footer'>
            <div className='socials d-flex flex-row justify-content-center'>
                {/* <img className='sc_icon' src='/images/commons/i64_ig.png' /> */}
                {/* <img className='sc_icon' src='/images/commons/i64_tw.png' /> */}
                <a href='https://www.facebook.com/megazombiemth/'><img className='sc_icon' src='/images/commons/i64_fb.png' /></a>
                <a href='https://www.youtube.com/@gmillionofficial/'><img className='sc_icon' src='/images/commons/i64_yt.png' /></a>
                <a href='https://discord.gg/jBEaHx9H7K'><img className='sc_icon' src='/images/commons/i64_dc.png' /></a>
                {/* <img className='sc_icon' src='/images/commons/i64_tg.png' /> */}
            </div>
            <hr className='line mx-auto' />
            <div className='copyright'>
                © 2023 G MILLION Co., Ltd. All Rights Reserved.
            </div>
        </div>
    )

}