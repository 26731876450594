import React, {useRef, useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material'; 
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import axios from "axios";
import Modal1 from '../../utils/components/modal1';


import "./quest_progress.scss"
import { Button } from 'bootstrap/dist/js/bootstrap.bundle.min';

export default function QuestProgress(){

    const [showRewardDetail, setShowRewardDetail] = useState(false);
    const [rewardsInfo, setrewardsInfo] = useState({
        images: [
            '/images/events/chapter1/reward-item01.png',
            '/images/events/chapter1/reward-item01.png',
            '/images/events/chapter1/reward-item01.png',
            '/images/events/chapter1/reward-item01.png',
            '/images/events/chapter1/reward-item01.png',
            '/images/events/chapter1/reward-item01.png',
            '/images/events/chapter1/reward-item01.png',
            '/images/events/chapter1/reward-item01.png',
        ],
        text: [ "7D", "7D", "7D", "7D", "7D", "7D", "7D", "7D",],
        point: ["10PT", "20PT", "30PT", "40PT", "50PT", "60PT", "70PT", "80PT"],
})

    const Reward_item = (rewards, index) => {
        return(
            <div className='quest-reward-item d-flex flex-row ms-0 me-2' 
                onClick={() => show_reward_detail(true)}
            >
                <img className='img-fluid m-auto p-auto' src='/images/events/chapter1/reward-item01.png' />
                <p className='quest-reward-item-text mb-1'>7D</p>
            </div>
        )
    }

    const Reward = (rewards, index) => {
        return(
            <div className='reward d-flex flex-column'>
                <div className='reward-item d-flex flex-column mx-auto' 
                    onClick={() => show_reward_detail(true)}
                >
                    <img className='reward-img img-fluid m-auto' src={rewards.images[index]} />
                    <p className='reward-text'>{rewards.text[index]}</p>
                </div>
                <img className='reward-mark img-fluid mx-auto mt-1' src='/images/events/chapter1/progress_mark_white.png' />
                <div className='reward-point mx-auto p-0'>{rewards.point[index]}</div>
            </div>
            
        )
    }

    const show_reward_detail = (isshow) => {
        setShowRewardDetail(isshow);
    }

    const ModalDetail = () => {
        return(
            <Modal1 
                open={showRewardDetail}
                onClose={() => setShowRewardDetail(false)}
                context={() => {
                    return(
                        <div className='d-flex flex-column m-2'>
                            <img className='reward-img img-fluid m-auto' src="/images/events/chapter1/gold.png" />
                            <div className='reward-title mx-auto mt-2'>
                                GOLD COIN 500G
                            </div>
                                        <div className='reward-info m-auto'>
                                test reward info are .......................
                            </div>
                        </div>
                    )
                }}
            />
        )
    }

    const progress_bar_mark = () => {
        return(
            <img className='bar-mark-item me-auto' src='/images/events/chapter1/progress_mark_white.png' />
        )
    }

    return(
        <div className='quest-progress-area d-flex flex-column'>
            <div className='bar-bg' />
            <div className='rewards d-flex flex-row justify-content-between mb-1'>
                {Reward(rewardsInfo, 0)}
                {Reward(rewardsInfo, 1)}
                {Reward(rewardsInfo, 2)}
                {Reward(rewardsInfo, 3)}
                {Reward(rewardsInfo, 4)}
                {Reward(rewardsInfo, 5)}
                {Reward(rewardsInfo, 6)}
                {Reward(rewardsInfo, 7)}
            </div>  

            <ModalDetail />
        </div>
    )
}